import {TgAuthorizationButton} from "features/tg-authorization-button";
import {CatDark, LogoImg, PageWrapper, TypographyH1, TypographyP} from "shared/components";

export const AuthorizationPage = () => {
    return (
        <PageWrapper>
            <div className="flex flex-col h-full">
                <div className="flex-col z-20">
                    <LogoImg/>
                    <TypographyH1 className="text-white mt-10">Салют, игрок!</TypographyH1>
                    <TypographyP className="text-white/80 mt-8">Мы помогаем игрокам найти подходящие события,
                        зарегистрироваться на них и общаться с другими участниками.</TypographyP>
                </div>
                <TgAuthorizationButton className="w-full mt-auto flex justify-center z-20" botName="PlatformUfa_bot" dataOnauth="handleTelegramAuth"/>
                <CatDark className="absolute top-[20%] inset-0 w-full h-full object-cover z-10"/>
            </div>
        </PageWrapper>
    )
}
import {FC, ReactNode} from "react";

interface IEventContentContainer {
    children: ReactNode;
    onClick?: () => {};
    className?: string;
}

export const EventContentContainer: FC<IEventContentContainer> = ({
                                                                      children,
                                                                      onClick,
                                                                      className
                                                                  }) => {
    return (
        <div className={`p-4 rounded-3xl w-full bg-white relative overflow-hidden z-10 ${className}`} onClick={onClick}>
            {children}
        </div>
    )
}
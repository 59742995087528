import {useGate} from "effector-react";
import {NavigationScrollBar} from "widgets/navigation-scroll-bar";
import {CreateEventCard} from "features/create-event-card";
import {MyTeamCard} from "features/my-team-card";
import {UserAvatar} from "features/user-avatar";
import {EventContentContainer, PageWrapper} from "shared/components";
import {MainPageGate} from "./model";


export const MainPage = () => {

    useGate(MainPageGate)

    return (
        <PageWrapper>
            <div className="flex flex-col gap-4">
                <UserAvatar/>
                <NavigationScrollBar/>
                <div className="flex flex-col gap-2">
                    <EventContentContainer>
                        <CreateEventCard/>
                    </EventContentContainer>
                    <EventContentContainer className="min-h-[118px]">
                        <MyTeamCard/>
                    </EventContentContainer>
                </div>
            </div>
        </PageWrapper>
    )
}


import {FC, ReactNode} from "react";

interface IScrollAreaContentContainer {
    children: ReactNode;
    onClick?: (arg: any) => {};
    backgroundColor: string;
    size: "small" | "large";
}

export const ScrollAreaContentContainer: FC<IScrollAreaContentContainer> = ({
                                                                                children,
                                                                                onClick,
                                                                                backgroundColor,
                                                                                size = "large"
}) => {
    return (
        <div className={`${backgroundColor} ${size === "large" ? "w-[304px] h-[304px]" : "w-[148px] h-[148px]"} p-4 rounded-3xl  relative overflow-hidden`} onClick={onClick}>
            {children}
        </div>
    )
}
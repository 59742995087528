import {FC} from "react";
import {Button, TypographyMuted, TypographyP} from "shared/components";
import {EditIcon} from "shared/components/icons/edit-icon";

interface ISportTypeCard {
    sportName: string;
    position: string;
    skill: string;
}

export const SportTypeCard: FC<ISportTypeCard> = ({
                                                      sportName,
                                                      position= "",
                                                      skill = ""
                                                  }) => {
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
                <img/>
                <TypographyP className="m-0">{sportName}</TypographyP>
            </div>
            <div className="flex items-center gap-6">
                <TypographyMuted>{`${position} · ${skill}`}</TypographyMuted>
                <Button variant="outline" size="icon" className="size-8"><EditIcon /></Button>
            </div>
        </div>
    )
}
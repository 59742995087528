import React, {FC} from "react";
import {useUnit} from "effector-react/compat";
import {OnboardingPage} from "pages/onboarding-page";
import {$sessionUser} from "entities/session";
import {UserDto} from "shared/api/generate/data-contracts";

interface IOnboardingGate {
    children: React.ReactElement
}
export const OnboardingProvider: FC<IOnboardingGate> = ({children}) => {
    const sessionUser = useUnit($sessionUser) as UserDto

    //@ts-expect-error //TODO isOnboarded - добавить поле в апи
    if (Boolean(sessionUser?.id) && sessionUser?.isOnboarded === false) {
        return <OnboardingPage/>
    }

    return children

}
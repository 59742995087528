import {Button, TypographyH3, TypographyMuted} from "shared/components";
import BasketballBall from "shared/assets/basketball-ball.png"

export const CreateEventCard = () => {
    return (
        <div className="flex flex-col gap-2 z-20">
            <div className="flex flex-col gap-2 z-20">
                <TypographyH3>Создай событие</TypographyH3>
                <TypographyMuted>Собери людей, чтобы поиграть</TypographyMuted>
            </div>
            <div className="z-20">
                <Button>Создать событие</Button>
            </div>
            <img className="absolute rotate-[27.502deg] size-[161.008px] right-[-30px] bottom-[-70px] z-10" src={BasketballBall} alt="BasketballBall"/>
        </div>
    )
}
import React, { ReactNode } from 'react';

interface IScrollArea {
    children: ReactNode;
}

export const ScrollArea: React.FC<IScrollArea> = ({ children }) => {
    return (
        <div className="min-w-[100%] -mx-4 px-4 gap-4 overflow-x-auto flex items-center scrollbar-hide">
            <div className="flex gap-2">
                {children}
            </div>
        </div>
    );
};

import React, {FC} from "react";

interface ITypography {
    children: string;
    className?: string;
    textColor?: string;
}

export const TypographyH1: React.FC<ITypography> = ({
                                                        children,
                                                        className
                                                    }) => {
    return (
        <h1 className={`scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl ${className}`}>
            {children}
        </h1>
    )
}

export const TypographyH3: React.FC<ITypography> = ({
                                                        children,
                                                        className,
                                                        textColor = "black"
                                                    }) => {
    return (
        <h3 className={`text-${textColor} font-inter text-[20px] font-semibold leading-[24px] tracking-[-0.1px] ${className}`}>
            {children}
        </h3>
    )
}

export const TypographyP: React.FC<ITypography> = ({
                                                       children,
                                                       className
                                                   }) => {
    return (
        <p className={`leading-7 [&:not(:first-child)]:mt-6 ${className}`}>
            {children}
        </p>
    )
}

export const TypographyMuted: FC<ITypography> = ({
                                                     children,
                                                     className
                                }) => {
    return (
        <p className={`text-slate-400 font-inter text-[14px] font-normal leading-[18px] ${className}`}>
            {children}
        </p>
    )
}
import {PhotoCloud, TypographyH3, TypographyMuted} from "shared/components";

export const MyTeamCard = () => {

    return (
        <div className="flex flex-col gap-2 z-10">
            <div className="flex flex-col gap-2 z-30">
                <TypographyH3>Моя команда</TypographyH3>
                <TypographyMuted>Приглашайте своих</TypographyMuted>
            </div>
            <PhotoCloud className="right-[261px] bottom-[109px] z-80"/>
        </div>
    )
}
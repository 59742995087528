import {createEffect} from "effector";
import {api} from "shared/api/api-instances";
import {TelegramBotDto, UserDto} from "shared/api/generate/data-contracts";

export const getUsersFx = createEffect<void, UserDto[]>(() => {
    return fetch("https://platformufa.ru/api/users", {
        credentials: 'include'
    })
        .then(response => response.json())
})

export const getUserFx = createEffect<number, UserDto, Error>((userId) => {
    return fetch(`https://platformufa.ru/api/users/${userId}`, {
        credentials: 'include'
    })
        .then(response => response.json())
})

export const updateUserFx = createEffect<object, void, Error>((payload) => api.updateUser(payload))

export const getSessionFx = createEffect<void, UserDto, Error>(() => api.findTgSession())

export const loginFx = createEffect<TelegramBotDto, UserDto, Error>((payload) => api.signIn(payload))
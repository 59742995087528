import {SportTypeCard} from "features/sport-type-card";
import {EventContentContainer} from "shared/components";

export const UserGames = () => {

    const userGamesTypes = [
        {sportName: "Футбол", position: "Нападающий", skill: "Начинающий"},
        {sportName: "Волейбол", position: "Защитник", skill: "Профи"},
        {sportName: "Баскетбол", position: "Блокирующий", skill: "Мидл"}
    ]

    return (
        <div className="flex flex-col gap-5">
            {
                userGamesTypes.map(({sportName, skill, position}) => {
                    return (
                        <EventContentContainer>
                            <SportTypeCard sportName={sportName} skill={skill} position={position}/>
                        </EventContentContainer>
                    )
                })
            }
        </div>
    )
}
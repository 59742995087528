import {createRoute} from "atomic-router";
import {createEvent, sample} from "effector";
import {attachedUpdateUserFx} from "entities/user";
import {UserDto} from "shared/api/generate/data-contracts";
import {$sessionUser} from "entities/session";

export const onboardingPageRoute = createRoute();
export const setOnboarded = createEvent<Partial<UserDto>>()

sample({
    clock: setOnboarded,
    target: attachedUpdateUserFx
})

sample({
    //@ts-expect-error
    source: $sessionUser,
    clock: attachedUpdateUserFx.doneData,
    fn: (sessionUser) => ({...sessionUser, isOnboarded: true}),
    target: $sessionUser
})
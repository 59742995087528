import React, {ReactNode} from "react";

interface IPageWrapper {
    children: ReactNode;
    backgroundColor?: string;
}

export const PageWrapper: React.FC<IPageWrapper> = ({
                                                        children,
                                                        backgroundColor = "bg-slate-800",
                                                    }) => {
    //@ts-expect-error
    const isTopSafeAreaInset = window.Telegram?.WebApp?.contentSafeAreaInset?.top //TODO 1) в типах нет поля safeAreaInset 2) Возможно есть способ лучше для определения в каком режиме открылось приложение
    const paddingTop = {
        paddingTop: isTopSafeAreaInset ? `calc(2rem + ${isTopSafeAreaInset}px)` : "1rem"  //TODO нужно paddingBottom так же высчитывать
    }

    return (
        <div className={`h-screen flex justify-center z-0 relative overflow-hidden ${backgroundColor}`}>
            <div className={`h-screen container px-2 pb-12 overflow-y-auto overflow-x-hidden`} style={paddingTop}>
                {children}
            </div>
        </div>
    )
}
import React, {ReactElement, useEffect} from "react";
import {useUnit} from "effector-react";
import {AuthorizationPage} from "pages/аuthorization-page";
import {useTelegram} from "shared/lib";
import {$isAuth, loginEvent, setInitDataUnsafe} from "./model";

interface IAuthGate {
    children: ReactElement;
}

//@ts-expect-error
window.handleTelegramAuth = (initDataUnsafe: handleTelegramAuth ) => {
    loginEvent(initDataUnsafe)
};

export const AuthProvider: React.FC<IAuthGate> = ({
                                 children
}) => {
    const {initDataUnsafe} = useTelegram();
    const isAuth = useUnit($isAuth)

    useEffect(() => {
        //@ts-expect-error
        setInitDataUnsafe(initDataUnsafe)
    }, [initDataUnsafe])

    if (!isAuth) {
        return <AuthorizationPage/>
    }

    // @ts-ignore
    return children
}
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EventDto, SimpleEventDto, SportDto, TelegramBotDto, UpdateUserDto, UserDto } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Profile
   * @name UpdateUser
   * @request PATCH:/api/profile
   */
  updateUser = (data: UpdateUserDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/profile`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name SignIn
   * @request POST:/api/auth/login
   */
  signIn = (data: TelegramBotDto, params: RequestParams = {}) =>
    this.request<UserDto, any>({
      path: `/api/auth/login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name FindTgSession
   * @request GET:/api/auth/session
   */
  findTgSession = (params: RequestParams = {}) =>
    this.request<UserDto, any>({
      path: `/api/auth/session`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events
   * @name FindAll
   * @request GET:/api/events
   */
  findAll = (
    query: {
      /** Код вида спорта */
      sport: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<SimpleEventDto[], any>({
      path: `/api/events`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Events
   * @name FindOne
   * @request GET:/api/events/{id}
   */
  findOne = (id: string, params: RequestParams = {}) =>
    this.request<EventDto, any>({
      path: `/api/events/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sports
   * @name FindAll2
   * @request GET:/api/sports
   * @originalName findAll
   * @duplicate
   */
  findAll2 = (params: RequestParams = {}) =>
    this.request<SportDto[], any>({
      path: `/api/sports`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sports
   * @name FindOne2
   * @request GET:/api/sports/{id}
   * @originalName findOne
   * @duplicate
   */
  findOne2 = (id: string, params: RequestParams = {}) =>
    this.request<SportDto, any>({
      path: `/api/sports/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
}

import React, {useEffect, useRef} from 'react';

interface ITelegramLoginButton {
    botName: string;
    dataOnauth: string;
    className: string;
}

export const TgAuthorizationButton: React.FC<ITelegramLoginButton> = ({ className, botName, dataOnauth }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-widget.js?22';
        script.async = true;
        script.setAttribute('data-telegram-login', botName);
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-onauth', `${dataOnauth}(user)`);
        script.setAttribute('data-request-access', 'write');
        containerRef.current?.appendChild(script);

        return () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, [botName, dataOnauth]);

    return <div className={className} ref={containerRef} id="telegram-login-button"></div>;
};
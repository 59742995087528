export const onboardingContent = [
    {
        title: "Заголовок",
        text: "Мы помогаем игрокам найти подходящие события, зарегистрироваться на них иобщаться сдругими участниками."
    },
    {
        title: "Заголовок 2",
        text: "Мы помогаем игрокам найти подходящие события, зарегистрироваться на них иобщаться сдругими участниками."
    },
    {
        title: "Заголовок 3",
        text: "Мы помогаем игрокам найти подходящие события, зарегистрироваться на них иобщаться сдругими участниками."
    },
]
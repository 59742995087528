import {createEvent, createStore, sample, split} from "effector";
import {condition} from "patronum";
import {redirect} from "atomic-router";
import {mainPageRoute} from "pages/main-page";
import {$sessionUser} from "entities/session";
import {HTTP_ERROR_CODES} from "shared/constants";
import {TelegramBotDto} from "shared/api/generate/data-contracts";
import {attachedLoginFx, loginEvent} from "./login";
import {attachedGetSessionFx} from "./session";

const login = createEvent()
export const setInitDataUnsafe = createEvent<TelegramBotDto>()
export const setIsAuth = createEvent<boolean>()

export const $initDataUnsafe = createStore<null | TelegramBotDto>(null)
export const $isAuth = createStore<boolean>(false)

sample({
    clock: setIsAuth,
    target:$isAuth
})

sample({
    clock: setInitDataUnsafe,
    target: $initDataUnsafe
})

sample({
    clock: $initDataUnsafe,
    target: attachedGetSessionFx
})

sample({
    clock: attachedGetSessionFx.doneData,
    target: $sessionUser
})

sample({
    clock: attachedLoginFx.doneData,
    target: $sessionUser
})

sample({
    //@ts-expect-error
    clock: attachedLoginFx.doneData,
    target: redirect({route: mainPageRoute})
})

//TODO добавить страницы для других ошибок
split({
    source: attachedGetSessionFx.failData,
    match: {
        //@ts-ignore
        [HTTP_ERROR_CODES.UNAUTHORIZED]: err => err.status === HTTP_ERROR_CODES.UNAUTHORIZED
    },
    cases: {
        [HTTP_ERROR_CODES.UNAUTHORIZED]: login
    }
})

condition({
    source: sample({
        source: $initDataUnsafe,
        clock: login,
    }),
    //@ts-ignore
    if: (initDataUnsafe) => Boolean(Object.keys(initDataUnsafe)?.length),
    then: loginEvent,
    //@ts-ignore
    else: setIsAuth.prepend(false)
})

sample({
    clock: $sessionUser,
    fn: () => true,
    filter: (sessionUser) => Boolean(sessionUser?.id),
    target: setIsAuth
})
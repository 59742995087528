import {UserGames} from "widgets/user-games";
import {UserAvatar} from "features/user-avatar";
import {
    Button,
    PageWrapper,
    ScrollArea,
    ScrollAreaContentContainer,
    TypographyH3
} from "shared/components";

export const UserProfilePage = () => {
    return (
        <PageWrapper>
            <div className="flex flex-col gap-4">
                <UserAvatar type="full" avatarSize="size-16"/>
                <div><Button variant="outline" >Редактировать данные</Button></div>
                <ScrollArea>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Радар</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Команда</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Виды спорта</ScrollAreaContentContainer>
                    <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Городские площадки</ScrollAreaContentContainer>
                </ScrollArea>
                <div className="flex flex-col gap-5">
                    <TypographyH3>Я играю</TypographyH3>
                    <UserGames/>
                    <div><Button variant="outline" >Добавить</Button></div>
                </div>

            </div>
        </PageWrapper>
    )
}
import {UpcomingEvents} from "features/upcoming-events";
import {ScrollAreaContentContainer} from "shared/components/ui/scroll-area-content-container";
import {ScrollArea} from "shared/components/ui/scroll-area";

export const NavigationScrollBar = () => {
    return (
        <ScrollArea>
            <ScrollAreaContentContainer backgroundColor="bg-orange-400" size="large"><UpcomingEvents/></ScrollAreaContentContainer>
            <div className="flex flex-wrap gap-2 w-[304px] h-[304px]">
                <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Block 2</ScrollAreaContentContainer>
                <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Block 3</ScrollAreaContentContainer>
                <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Block 4</ScrollAreaContentContainer>
                <ScrollAreaContentContainer backgroundColor="bg-slate-400" size="small">Block 5</ScrollAreaContentContainer>
            </div>
        </ScrollArea>
    )
}
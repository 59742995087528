import React from "react";
import {useUnit} from "effector-react/compat";
import {$sessionUser} from "entities/session";
import {Avatar, AvatarImage} from "shared/components";
import defaultAvatar from "shared/assets/default-avatar.svg"
import {DEFAULT, FULL} from "./constants";

interface IUserAvatar {
    type?: "default" | "full" | "edit";
    avatarSize?: "size-10" | "size-16";
    onClick?: () => void;
}

export const UserAvatar: React.FC<IUserAvatar> = ({
                                                      type = DEFAULT,
                                                      avatarSize = "size-10",
                                                      onClick
                                                  }) => {
    const sessionUser = useUnit($sessionUser)
    const userName = sessionUser?.userName

    let avatarBlockView
    switch (type) {
        case DEFAULT:
            avatarBlockView =
                <div className="text-white text-base">{userName ? `@${userName}` : sessionUser?.firstName}</div> //TODO 1)Поменять цвет на токен 2)Поменять имя на юзернейм из телеграма
            break;
        case FULL:
            avatarBlockView = <div>
                <div className="text-white text-base">{sessionUser?.firstName}</div>
                {/*//TODO Поменять имя на юзернейм из телеграма*/}
                {userName && <div className="text-white text-sm">@{userName}</div>}
            </div>
    }

    return (
        <div className="flex items-center gap-2 pl-2" onClick={onClick}>
            <Avatar className={`${avatarSize}`}>
                <AvatarImage src={sessionUser?.photoUrl ?? defaultAvatar}/>
            </Avatar>
            {avatarBlockView}
        </div>
    )
}
import {attach, createEvent, sample} from "effector";
import {loginFx} from "shared/api/querys-effects";
import {TelegramBotDto} from "shared/api/generate/data-contracts";

export const attachedLoginFx = attach({effect:loginFx})

export const loginEvent = createEvent<TelegramBotDto>()

sample({
    clock: loginEvent,
    target: attachedLoginFx
})
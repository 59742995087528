import React from "react";
import {RouteInstance} from "atomic-router";
import {MainPage, mainPageRoute} from "./main-page";
import {AuthorizationPage, authorizationPageRoute} from "./аuthorization-page";
import {UserProfilePage, userProfilePageRoute} from "./user-profile-page";
import {EventPage, eventPageRoute} from "./event-page";
import {OnboardingPage, onboardingPageRoute} from "./onboarding-page";


export type IRoutes = {
    pageName: string;
    path: string;
    route: RouteInstance<any>;
    component: React.FC;
};

export const routes: IRoutes[] = [
    { pageName: "Главная страница", path: '/', route: mainPageRoute, component: MainPage },
    { pageName: "Страница авторизации", path: '/authorization', route: authorizationPageRoute, component: AuthorizationPage },
    { pageName: "Профиль пользователя", path: '/profile', route: userProfilePageRoute, component: UserProfilePage },
    { pageName: "События", path: '/event', route: eventPageRoute, component: EventPage },
    { pageName: "Ознакомление", path: '/onboarding', route:onboardingPageRoute, component: OnboardingPage}
]




import React from 'react';
import {AuthProvider, OnboardingProvider} from "./providers";
import {RoutesView} from "./model";

export const App = () => {
    return (
        <AuthProvider>
            <OnboardingProvider>
                <RoutesView/>
            </OnboardingProvider>
        </AuthProvider>
    )
}
import React from "react";
import {useUnit} from "effector-react";
import {$sessionUser} from "entities/session";
import {Onboarding} from "shared/components";
import {UserDto} from "shared/api/generate/data-contracts";
import {onboardingContent} from "./constants";
import {setOnboarded} from "./model";

export const OnboardingPage = () => {
    const sessionUser = useUnit($sessionUser) as UserDto

    return <Onboarding
        isOpen={true}
        onboardingContent={onboardingContent}
        lastSlideOnClick={() => setOnboarded({userId: sessionUser?.id, isOnboarded: true} as Partial<UserDto>)}
    />
}
import {Button, CatLight, TypographyH3, TypographyMuted} from "shared/components";

export const UpcomingEvents = () => {


    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col gap-2 z-20">
                <TypographyH3 textColor="white">Ближайшее событие</TypographyH3>
                {/*//TODO поменять на нужный текстфилд*/}
                <TypographyMuted className="text-white">Все события в радиусе 200км</TypographyMuted>
            </div>
            <div className="w-full mt-auto z-20">
                <Button>Найти событие</Button>
            </div>
            <CatLight className="absolute right-[-3px] bottom-[-2px] z-10" width="261.664" height="257.74"/>
        </div>
    )
}